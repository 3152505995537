import { graphql, PageProps } from "gatsby"
import React, { useMemo } from "react"
import { ContentfulLotDetailPageQuery } from "../../../types/gatsby-graphql"
import { BuyButton } from "../../components/BuyButton/BuyButton"
import { NFTCard } from "../../components/NFTCard/NFTCard"
import { CapitalizeAll } from "../../utils"
import Loadable from "@loadable/component"

import * as styles from "./ArtworkDetail.module.scss"

const ArtworkDetail = ({ data }: PageProps<ContentfulLotDetailPageQuery>) => {
  const LoadableAuctionMonitor = Loadable(
    () => import("../../components/AuctionMonitor/AuctionMonitor")
  )
  const parsedData = useMemo(() => {
    return data.contentfulLot
  }, [data])

  return (
    <div className={styles.artworkDetailPage}>
      <h1 className={styles.artworkDetailTitle}>Collect this artwork</h1>

      <LoadableAuctionMonitor
        auctionId={parsedData?.auction?.contentfulid!}
        render={({ data: auction }: any) => {
          const enrichedData = {
            lot: parsedData,
            auction,
          }

          return (
            <div className={styles.artworkDetailContainer}>
              <NFTCard
                classname={styles.artworkDetailNftCard}
                title={enrichedData?.lot?.auction?.mint?.name!}
                imgUrl={
                  enrichedData?.lot?.auction?.mint?.mediaAsset?.media?.file
                    ?.url!
                }
                by={`${enrichedData?.lot?.artist?.firstName} ${enrichedData?.lot?.artist?.lastName}`}
                price={enrichedData?.auction?.reservePriceInETH!}
                assetType={
                  enrichedData?.lot?.auction?.mint?.mediaAsset?.media?.mimeType
                }
              />
              <div>
                <div className={styles.artworkDetailItem}>
                  <h3>Artwork Name: </h3>
                  <span>{enrichedData?.lot?.auction?.mint?.name!}</span>
                </div>
                <div className={styles.artworkDetailItem}>
                  <h3>Artist: </h3>
                  <span>
                    {CapitalizeAll(
                      [
                        enrichedData?.lot?.artist?.firstName,
                        enrichedData?.lot?.artist?.middleName,
                        enrichedData?.lot?.artist?.lastName,
                      ].join(" ")
                    )}
                  </span>
                </div>
                <div className={styles.artworkDetailItem}>
                  <h3>Edition: </h3>
                  <span>
                    {enrichedData?.auction?.quantity} /{" "}
                    {enrichedData?.auction?.nft?.quantity}
                  </span>
                </div>
                <div className={styles.artworkDetailItem}>
                  <h3>Total: </h3>
                  <span>{`${enrichedData?.auction?.reservePriceInETH.toFixed(
                    2
                  )} ETH`}</span>
                </div>
                <div className={styles.artworkDetailItem}>
                  <h3>Payment method: </h3>
                  <span>Crypto</span>
                </div>

                {enrichedData?.auction && (
                  <BuyButton
                    data={enrichedData?.lot}
                    auction={enrichedData?.auction}
                  />
                )}
              </div>
            </div>
          )
        }}
      />
    </div>
  )
}

export default ArtworkDetail

export const artWorkDetailQuery = graphql`
  query ContentfulLotDetailPage($contentful_id: String!) {
    contentfulLot(contentful_id: { eq: $contentful_id }) {
      contentful_id
      ended
      lotId
      externalSiteUrl
      auction {
        id
        contentfulid
        contentful_id
        maximumIncrease
        reservePriceInEth
        quantity
        percentageIncrease
        status
        type
        name
        mint {
          tokenId
          name
          contentfulid
          contentful_id
          description {
            description
          }
          mediaAsset {
            media {
              file {
                url
              }
              title
              mimeType
              description
            }
            main
          }
          id
          artist
          auction {
            quantity
            reservePriceInEth
            percentageIncrease
            maximumIncrease
          }
        }
      }
      sale {
        name
        nftType
      }
      artist {
        username
        middleName
        lastName
        firstName
        walletAddress
      }
    }
  }
`
