// extracted by mini-css-extract-plugin
export var alertContainer = "BuyButton-module--alertContainer--320ba";
export var artWorkDetailNavigationButton = "BuyButton-module--artWorkDetailNavigationButton--796c4";
export var auctionDetailsBidInput = "BuyButton-module--auctionDetailsBidInput--94f69";
export var auctionDetailsButton = "BuyButton-module--auctionDetailsButton--e544f";
export var bidDetails = "BuyButton-module--bidDetails--aeb85";
export var buyButtonConnectModal = "BuyButton-module--buyButtonConnectModal--ca8f3";
export var buyButtonModal = "BuyButton-module--buyButtonModal--571ee";
export var error = "BuyButton-module--error--6b6d4";
export var howBiddingWorks = "BuyButton-module--howBiddingWorks--63fcc";
export var indicator = "BuyButton-module--indicator--b79fe";
export var minimumBid = "BuyButton-module--minimumBid--857a8";
export var pulse = "BuyButton-module--pulse--6b18d";
export var success = "BuyButton-module--success--4c6d3";