import { AuctionStatus, AuctionType } from "@monegraph/graph"
import cx from "classnames"
import { get, includes, filter } from "lodash"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useRecoilState } from "recoil"
import useBuyButton, { BuyButtonState } from "../../hooks/use-buy-button"
import { AuthState, isAuthenticatedState } from "../../state/user"
import { Action, track } from "../../utils/analytics"
import Modal from "../Modal/Modal"

import MetamaskLogo from "../../images/metamask.svg"
import WalletConnectLogo from "../../images/walletconnect.svg"

import * as styles from "./BuyButton.module.scss"
import { isFuture } from "date-fns"
import { AssociateWalletWithUserRequestData } from "../../utils/auction"
import { showConnectWalletState } from "../../state/connectWallet"

export const BuyButton = ({ data, auction }: any) => {
  const [isLoading, setIsLoading] = useState(false)
  const [amount, setAmount] = useState("")
  const [currentConnectedWalletAddress, setCurrentConnectedWalletAddress] =
    useState("")
  const [error, setError] = useState<null | string>()
  const [openConnectModal, setOpenConnectModal] = useState(false)
  const [authState] = useRecoilState(isAuthenticatedState)
  const [showModal, setShowModal] = useState(false)
  const [, setShowConnectWallet] = useRecoilState(showConnectWalletState)

  const {
    buttonState,
    clearErrors,
    errorState,
    wallet,
    onBuy,
    onSettle,
    minimumBid,
  } = useBuyButton(auction)

  useMemo(() => {
    if (wallet.status === "connected") {
      setOpenConnectModal(false)
    }

    if (wallet.connector === "walletconnect") {
      if (wallet.ethereum) {
        wallet.ethereum.on = null
      }
    }
  }, [wallet.status, wallet.connector, wallet.ethereum])

  const userBids = useMemo(() => {
    return filter(auction?.bids, (bid: any) => {
      return includes([wallet?.account?.toLowerCase()], bid.bidder.id)
    })?.length
  }, [auction?.history, wallet?.account])

  useEffect(() => {
    setIsLoading(false)
  }, [userBids])

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount((event?.target?.value || "").replace(/[^0-9.]/g, ""))
  }

  const onBuyClick = useCallback(async () => {
    setIsLoading(true)

    track(Action.BID, { auctionId: auction?.id })

    try {
      clearErrors()

      try {
        await onBuy(amount ? amount : auction?.minimumBid)
      } catch (error) {
        console.log("CODE", error?.code)
        if (error?.code) {
          switch (error?.code) {
            case -32000:
              setError(
                "Not enough funds in your wallet to complete the transaction!"
              )
              break
            case 4001:
              setIsLoading(false)
            default:
          }
        }
      }

      setIsLoading(false)
    } catch (error) {}
  }, [
    amount,
    auction?.id,
    auction?.minimumBid,
    clearErrors,
    onBuy,
    wallet?.account,
  ])

  console.log(error)
  const onLogIn = useCallback(() => {
    window.location.assign(
      `${process.env.GATSBY_OAUTHAPI}?client_id=${
        process.env.GATSBY_CLIENT_ID
      }&redirect_uri=${process.env.GATSBY_REDIRECT_URI}?r=${encodeURIComponent(
        window.location.href
      )}&response_type=code`
    )
  }, [])

  const buttonProps = useMemo(() => {
    if (auction) {
      if (isFuture(auction.dateStarted)) {
        return { label: "Coming soon", disabled: true }
      }
    }

    if (data.ended === true) {
      return { label: "Closed", disabled: true }
    }

    if (isLoading) {
      return { label: "processing...", disabled: true }
    }

    if (
      buttonState === BuyButtonState.Biddable ||
      buttonState === BuyButtonState.Buyable
    ) {
      if (
        wallet.status === "connected" &&
        currentConnectedWalletAddress !== wallet.account
      ) {
        track(Action.CONNECTWALLET, {
          wallet: wallet.account,
          partner: "dafax",
          eventType: Action.CONNECTWALLET,
        } as AssociateWalletWithUserRequestData)
        setCurrentConnectedWalletAddress(wallet.account!)
      }

      if (!wallet.account) {
        return {
          label: buttonState === BuyButtonState.Biddable ? "Bid" : "Buy Now",
          onClick: () => setShowConnectWallet(true),
        }
      }
    }

    if (buttonState === BuyButtonState.Biddable) {
      if (wallet.status === "connected") {
        return {
          label: "Bid",
          onClick: onBuyClick,
        }
      }
    } else if (buttonState === BuyButtonState.Buyable) {
      if (wallet.status === "connected") {
        return {
          label: "Buy Now",
          onClick: onBuyClick,
        }
      }
    } else if (buttonState === BuyButtonState.Sold) {
      return { label: "Closed", onClick: () => {}, disabled: true }
    } else {
      return { label: "Closed", onClick: () => {}, disabled: true }
    }
  }, [
    auction?.seller?.id,
    authState,
    buttonState,
    isLoading,
    onBuyClick,
    onLogIn,
    onSettle,
    wallet?.account,
    wallet?.status,
    currentConnectedWalletAddress,
  ])

  const alertProps = useMemo(() => {
    if (authState === AuthState.authenticated) {
      if (errorState) {
        return {
          label: errorState,
          style: {
            color: "rgb(176, 0, 32)",
          },
        }
      }

      if (
        wallet?.account?.toLowerCase() ===
        auction?.highestBid?.bidder?.id?.toLowerCase()
      ) {
        return {
          label: "You are currently in the lead.",
          style: {},
          indicator: "success",
        }
      } else if (userBids > 0) {
        return {
          label: "Someone else has taken the lead.",
          style: {},
          indicator: "error",
        }
      }
    }
  }, [auction?.highestBid?.bidder?.id, authState, errorState, userBids])

  const inputError = useMemo(() => {
    if (
      auction?.type === AuctionType.TRADITIONAL &&
      !new RegExp(/^\d*\.?\d*$/).test(amount)
    ) {
      return "Invalid bid"
    }
    return null
  }, [amount, auction?.type])

  const bidInput = useMemo(() => {
    return buttonState === BuyButtonState.Biddable ? (
      <>
        <input
          placeholder={`Enter Bid: ${parseFloat(minimumBid).toFixed(
            2
          )} minimum`}
          value={amount}
          onChange={onChange}
          disabled={
            get(auction, "status", AuctionStatus.OPEN) !== AuctionStatus.OPEN
          }
        />
        {inputError ? <span style={{ color: "red" }}>{inputError}</span> : null}
        {error ? <span style={{ color: "red" }}>{error}</span> : null}
      </>
    ) : null
  }, [amount, auction, buttonState, inputError, minimumBid, error])

  if (!auction) {
    return null
  }

  return (
    <div>
      {data.ended !== true ? (
        <>
          <div className={styles.auctionDetailsBidInput}>{bidInput}</div>
        </>
      ) : null}

      <div className={styles.auctionDetailsButton}>
        {buttonProps && (
          <button
            variant="primaryBlack"
            block
            width="100%"
            onClick={buttonProps?.onClick}
            disabled={buttonProps?.disabled || !!inputError}
          >
            {buttonProps?.label}
          </button>
        )}
        {alertProps && (
          <div
            className={cx(
              styles.alertContainer,
              styles[alertProps.indicator || ""]
            )}
            style={alertProps.style}
          >
            {alertProps?.label}
            <div className={styles.indicator}></div>
          </div>
        )}
      </div>
      {error && <span style={{ color: "red" }}>{error}</span>}

      {showModal && (
        <Modal closeModal={() => setShowModal(false)}>
          <div className={styles.buyButtonModal}>
            <h3>Connect Metamask</h3>
            <p>
              In order to bid you'll need to create and connect a MetaMask
              wallet. Download and install the MetaMask browser extension
              through their website:
              <a
                href="https://metamask.io/download.html"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                click here
              </a>
            </p>
          </div>
        </Modal>
      )}

      {openConnectModal && (
        <Modal
          mobileFullScreen={true}
          closeModal={() => setOpenConnectModal(false)}
        >
          <div className={styles.buyButtonConnectModal}>
            <button
              onClick={() => {
                setShowModal(true)
              }}
              className="flex justify-start text-left px-5 py-10 text-center items-center gap-3 hover:bg-slate-100 w-full"
            >
              <img src={MetamaskLogo} width="50px" />
              <span className="text-lg">Metamask</span>
            </button>

            <hr />

            <button
              onClick={() => null}
              className="flex justify-start text-left px-5 py-10 text-center items-center gap-3 hover:bg-slate-100 w-full"
            >
              <img src={WalletConnectLogo} width="50px" />
              <span className="text-lg">Wallet Connect</span>
            </button>
          </div>
        </Modal>
      )}
    </div>
  )
}
