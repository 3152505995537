import { useCallback, useMemo, useState } from "react"

import BN from "bignumber.js"
import { get } from "lodash"
import { useWallet } from "use-wallet"
import web3 from "web3"

import { useContract, executeMethod } from "@monegraph/contracts"

import organizationAuction from "../contracts/organization-auction"

import { AuctionStatus, AuctionType } from "@monegraph/graph"

export enum BuyButtonState {
  Biddable,
  Buyable,
  Settleable,
  Sold,
}

export default function useBuyButton(auction: any) {
  const wallet = useWallet()
  const [errorState, setErrorState] = useState<string | null>()

  const { isFinalized } = useMemo(() => {
    return {
      isFinalized:
        get(auction, "status", AuctionStatus.OPEN) === AuctionStatus.FINALIZED,
    }
  }, [auction])

  const buttonState = useMemo(() => {
    if (isFinalized) {
      return BuyButtonState.Sold
    } else if (get(auction, "hasEnded")) {
      return BuyButtonState.Settleable
    } else if (auction?.type === AuctionType.BUYNOW) {
      return BuyButtonState.Buyable
    } else {
      return BuyButtonState.Biddable
    }
  }, [auction, isFinalized, wallet.status])

  const contract = useContract(organizationAuction, {
    address: auction?.contract,
  })

  const onSettle = useCallback(async () => {
    try {
      if (!isFinalized && auction.hasEnded) {
        await executeMethod(contract.finalize, [auction.index], {
          from: wallet.account,
        })

        setErrorState(null)
      }
    } catch (e) {
      switch (e.code) {
        // user rejected the transaction
        case 4001:
          throw e

        // insufficient funds
        case -32000:
          setErrorState(
            "Please deposit ETH into your MetaMask wallet. There are currently insufficient funds to place your bid."
          )
          throw e

        default:
          throw e
      }
    }
  }, [
    isFinalized,
    auction.hasEnded,
    auction.index,
    contract?.address,
    wallet.account,
  ])

  const onBuy = useCallback(
    async bid => {
      if (process.env.GATSBY_ETHERNET_CHAIN_ID == wallet.chainId) {
        const minimumBid = BN.isBigNumber(auction.minimumBid)
          ? auction.minimumBid
          : new BN(auction.minimumBid)

        const bidInWei =
          typeof bid === "string" ? web3.utils.toWei(bid, "ether") : minimumBid

        try {
          await executeMethod(contract.bid, [auction.index], {
            from: wallet.account,
            value: minimumBid.gt(bidInWei) ? auction.minimumBid : bidInWei,
          })
          setErrorState(null)
        } catch (e) {
          switch (e.code) {
            // user rejected the transaction
            case 4001:
              throw e

            // insufficient funds
            case -32000:
              setErrorState(
                "Please deposit ETH into your metamask wallet, insufficient funds to complete transaction."
              )
              throw e

            default:
              throw e
          }
        }
      } else {
        alert("Please connect to the ethereum mainnet and try again")
      }
    },
    [
      contract,
      wallet.account,
      auction.minimumBid,
      auction.index,
      wallet.chainId,
    ]
  )

  const clearErrors = () => setErrorState(null)

  const minimumBid = useMemo(() => {
    return web3.utils.fromWei(auction.minimumBid.toString(), "ether")
  }, [auction.minimumBid])

  return {
    buttonState,
    errorState,
    minimumBid,
    wallet,
    onSettle,
    onBuy,
    clearErrors,
  }
}
